<template>
	<router-view />
</template>

<style>
	* {
		padding: 0px;
		margin: 0px;
	}

	html,
	body {
		width: 100%;
		height: 100%;
	}

	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
	}
</style>

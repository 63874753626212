<template>
	<div class="home">
		<div class="page_screen page_index" :style="{height: height + 'px'}">
			<div class="page_index_image_01"><img src="../assets/index_01.png" width="500" /></div>
			<div class="page_index_right">
				<img class="page_index_info" src="../assets/index_02.png" />
				<div class="download_button">
					<img src="../assets/icon_qrcode.png" width="24" />
					<span style="margin-left: 8px;">扫码下载</span>
				</div>
			</div>
		</div>
		<div class="page_screen page_01" :style="{height: height + 'px'}">
			<div class="layout_left_img">
				<img src="../assets/info_01.png" :height="height * 0.6" />
			</div>
			<div class="layout_right_text">
				<img src="../assets/info_txt_01.png" width="500" />
			</div>
		</div>
		<div class="page_screen page_02" :style="{height: height + 'px'}">
			<div class="layout_left_text">
				<img src="../assets/info_txt_02.png" width="500" />
			</div>
			<div class="layout_right_img">
				<img src="../assets/info_02.png" :height="height * 0.6" />
			</div>
		</div>
		<div class="page_screen page_03" :style="{height: height + 'px'}">
			<div class="layout_left_img">
				<img src="../assets/info_03.png" :height="height * 0.6" />
			</div>
			<div class="layout_right_text">
				<img src="../assets/info_txt_03.png" width="500" />
			</div>
		</div>
		<div class="footer">
			<div class="copyright">
				<span>慧知科技版权所有 ©️ 2021 - 惠久客 - </span>
				<a target="_blank" href="https://beian.miit.gov.cn/" rel="nofollow">京ICP备19027872号</a>
				<span> - </span>
				<a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11011402011393" rel="nofollow">京公网安备11011402011393号</a>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Home',
		data() {
			return {
				height: 600,
			}
		},
		components: {},
		mounted() {
			this.height = window.innerHeight;
			window.addEventListener('resize', this.onResize);
		},
		unmounted() {
			window.removeEventListener('resize', this.onResize);
		},
		methods: {
			onResize() {
				this.height = window.innerHeight;
			}
		}
	}
</script>

<style>
	.home {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.page_screen {
		width: 1200px;
		height: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		align-items: center;
	}

	.page_index {}

	.page_index_right {
		width: 38%;
		height: 100%;
		background-color: #DE0000;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-evenly;
	}

	.page_index_right .page_index_info {
		width: 60%;
	}

	.download_button {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		width: 280px;
		height: 45px;
		font-size: 16px;
		font-weight: bold;
		color: #000;
		border-radius: 45px;
		background-color: #FFF;
	}

	.footer {
		display: flex;
		width: 100%;
		height: 180px;
		align-items: center;
		justify-content: center;
		background-color: #f2f2f2;
		color: #999;
	}

	.footer a {
		color: #666;
	}
</style>
